// import { Typography12 } from "../../components/Typography/Typography";

import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  Stack,
  TablePagination,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import ApiService from "../../APIs/api";
// import { ErrorMsg } from "../../components/ErrorMsg";
// import { SuccessMsg } from "../../components/SuccessMsg";
// import { calculateMaxDate, formatDateString } from "../../utils/Constant";
import dayjs from "dayjs";
import ApiService from "../../api/api";
import { Typography12 } from "../../components/typography/Typography";
import { ErrorMsg } from "../../components/Messages/ErrorMsg";
import { SuccessMsg } from "../../components/Messages/SuccessMsg";
import { formatDateString } from "../../constant/generalMixing";
// import ApiService from "../../../../APIs/api";

const Main = () => {
  const token = useSelector((state: any) => state.user.userLogin.token);
  const [isReportDownloaded, setIsReportDownloaded] = useState(false);
  const [reportIdentifier, setReportIdentifier] = useState<any>("");
  const [isRequestCompleted, setIsRequestCompleted] = useState(false);
  // user Kyc Status for approver or new or rejected state

  const [startDate, setstartDate] = useState(null);
  const [EndDate, setEndDate] = useState(null);

  const identifierType = [
    { value: "order", label: "ORDERS" },
    { value: "paymentRequest", label: "INVOICE" },
    { value: "invoice", label: "PAYMENT" },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        width: 140,
        borderRadius: "8px",
      },
    },
  };
  const handleonChangeForDate = (value: any) => {
    // const selectedDate = accurateDateFormat(value);
    value = formatDateString(value);
    setstartDate(value);
  };

  const handleonChangeForDate1 = (value: any) => {
    // const selectedDate = accurateDateFormat(value);
    value = formatDateString(value);
    setEndDate(value);
  };

  const fetchReports = async () => {
    setIsReportDownloaded(true);

    if (!reportIdentifier || !startDate || !EndDate) {
      ErrorMsg("Please fill all fields before submitting.");
      return;
    }
    setIsRequestCompleted(true);
    ApiService.setToken(token);

    try {
      const responseData: any = await ApiService.get(
        `checkerRoutes/reports/${reportIdentifier}?startDate=${startDate}&endDate=${EndDate}`
      );
      if (responseData.status === 200) {
        let message: any = responseData.data.message;
        setIsReportDownloaded(false);
        SuccessMsg(message);
      } else {
        setIsReportDownloaded(false);
        throw new Error("Failed to download report");
      }
    } catch (error) {
      setIsReportDownloaded(false);
      ErrorMsg("Something went wrong, Please try again!");
      console.log(error);
    }
  };
  const clearSearchHistory = () => {
    setstartDate(null);
    setEndDate(null);
    setReportIdentifier("");
  };

  return (
    <Box
      justifyContent={"center"}
      component={"div"}
      sx={{
        // backgroundColor: "#F7F9FB",
        minHeight: "90vh",
      }}
    >
      <Stack mx={4} sx={{ marginTop: { xs: "110px", md: "50px" } }}>
        <Typography
          fontFamily={"Public Sans"}
          fontSize={22}
          sx={{ mt: 4 }}
          fontWeight={700}
          fontStyle={"normal"}
          color={"#000000"}
        >
          Bulk Export Reports
        </Typography>
      </Stack>
      <Box
        mx={{ xs: 0, md: 6 }}
        border="1px solid var(--grey-300, #DFE3E8)"
        boxShadow={
          "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
        }
        borderRadius={"16px"}
        my={3}
        py={2}
        p={2}
        sx={{ backgroundColor: "white" }}
      >
        <>
          <Stack
            sx={{ py: 1, px: 1 }}
            display={{ xs: "block", sm: "flex" }}
            direction={"row"}
            spacing={2}
          >
            <Box>
              <FormControl sx={{ ml: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Start Date"
                      format="DD/MM/YYYY"
                      sx={{
                        style: {
                          borderRadius: "8px",
                        },
                      }}
                      value={startDate}
                      onChange={(value: any) => handleonChangeForDate(value.$d)} // Handle date change with formatted value
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: {
                            "& .MuiInputBase-root": {
                              height: 50,
                              maxWidth: "100%",
                              borderRadius: "8px",
                            },
                            "& .MuiFormLabel-root": {
                              color: "#919EAB",
                              fontFamily: "Public Sans",
                              fontWeight: "500",
                              fontSize: "14px",
                              mt: 0.2,
                            },
                            borderRadius: "16px",
                            borderColor: "#919EAB",
                            marginTop: 0,
                          },
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>
            </Box>
            <Box sx={{ ml: { xs: 0, sm: 2 } }}>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="End Date"
                      format="DD/MM/YYYY"
                      maxDate={dayjs()}
                      sx={{
                        style: {
                          borderRadius: "8px",
                        },
                      }}
                      value={EndDate}
                      onChange={(value: any) =>
                        handleonChangeForDate1(value ? value.$d : "")
                      }
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: {
                            "& .MuiInputBase-root": {
                              height: 50,
                              maxWidth: "100%",
                              borderRadius: "8px",
                            },
                            "& .MuiFormLabel-root": {
                              color: "#919EAB",
                              fontFamily: "Public Sans",
                              fontWeight: "500",
                              fontSize: "14px",
                              mt: 0.2,
                            },
                            borderRadius: "16px",
                            borderColor: "#919EAB",
                            marginTop: 0,
                          },
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>
            </Box>
            <Box sx={{ pt: 1 }}>
              <FormControl sx={{width:{xs:"100%",sm:"15vw"}}}
              >
                <InputLabel
                  sx={{
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  Select Report Type
                </InputLabel>
                <Select
                  value={reportIdentifier ? reportIdentifier : ""}
                  onChange={(e) => setReportIdentifier(e.target.value)}
                  sx={{
                    border: "none",
                    borderRadius: "8px",
                    height: "50px",
                  }}
                  input={
                    <OutlinedInput
                      sx={{
                        borderRadius: "8px",
                        "& .MuiFormLabel-root": {
                          fontSize: "8px",
                        },
                      }}
                      label="Select Type"
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {identifierType.map((name, index) => (
                    <MenuItem key={index} value={name.value}>
                      <Stack display={"flex"} direction={"row"} spacing={1}>
                        <Typography
                          fontFamily={"Public Sans"}
                          fontSize={"14px"}
                          fontWeight={400}
                        >
                          {name.label}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Stack sx={{ display: "flex", flexDirection: "row" }}>
              <Box>
                <Button
                  onClick={fetchReports}
                  disabled={!reportIdentifier || !startDate || !EndDate}
                  size="small"
                  sx={{
                    mt: 1,
                    px: 3,
                    py: 2,
                    // ml: 1,
                    borderRadius: "8px",
                    backgroundColor:
                      !reportIdentifier || !startDate || !EndDate
                        ? "#dddddd" // Background color when disabled
                        : "#303981", // Background color when enabled
                    textAlign: "right",
                    fontFamily: "Public Sans",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#303981", // Hover background color when enabled
                    },
                  }}
                >
                  <Typography12
                    color={"white"}
                    text={"Download"}
                  ></Typography12>
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={clearSearchHistory}
                  size="small"
                  sx={{
                    color: "white",
                    mt: 1,
                    px: 3,
                    py: 2,
                    ml: 1,
                    borderRadius: "8px",
                    backgroundColor: "#303981",
                    textAlign: "right",
                    fontFamily: "Public Sans",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#303981",
                    },
                  }}
                >
                  <Typography12 color={"white"} text={"Clear"}></Typography12>
                </Button>
              </Box>
            </Stack>
          </Stack>
        </>
      </Box>
    </Box>
  );
};

export default Main;
