import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import shapeResend from "../../../assets/resend.svg";
import { useNavigate } from "react-router-dom";
import { loginUser, storeToken, userInfo } from "../../../redux/slices/user";
import { useDispatch, useSelector } from "react-redux";
import SimpleLoader from "../../../components/Loader";
import ApiService from "../../../api/api";
import { SuccessMsg } from "../../../components/Messages/SuccessMsg";
import { ErrorMsg } from "../../../components/Messages/ErrorMsg";

const OtpVerifyForm = () => {
  const dispatch = useDispatch();
  const storeUserName = useSelector((state: any) =>
    state.user.userSave.username ? state.user.userSave : ""
  );
  const [User, setUser] = useState<any>([]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const items = localStorage.getItem("User");
    setUser(items);
  });
  const navigate = useNavigate();
  const [emailOTP, setEmailOTP] = useState("");
  const [secondsEmail, setSecondsEmail] = useState<any>(0);
  const [resendEmailBtn, setresendEmailBtn] = useState(false);
  const [disabledEmailVerifybtn, setdisabledEmailVerifybtn] = useState(true);
  const [errorMobileVerifystatus, seterrorMobileVerifystatus] = useState(false);
  const [MobileFieldDisabled, setMobileFieldDisabled] = useState(false);

  const [mobileOTP, setMobileOTP] = useState("");
  const [seconds, setSeconds] = useState<any>(0);
  const [resendMobileBtn, setresendMobileBtn] = useState(false);
  const [disabledmobileVerifybtn, setdisabledmobileVerifybtn] = useState(true);
  const [MobileVerifyStatus, setMobileVerifyStatus] = useState(false);

  const handleOnChangeMobileOtp = (e: any) => {
    // mobile Otp Enter
    seterrorMobileVerifystatus(false);
    setMobileOTP(e.target.value);

    if (e.target.value !== "") {
      setdisabledmobileVerifybtn(false);
    } else {
      setdisabledmobileVerifybtn(true);
      console.log("empty");
    }
  };
  const handleOnChangeEmailOtp = (e: any) => {
    // mobile Otp Enter
    setEmailOTP(e.target.value);

    if (e.target.value !== "") {
      setdisabledEmailVerifybtn(false);
      console.log("not empty");
    } else {
      setdisabledEmailVerifybtn(true);
      console.log("empty");
    }
  };
  const mobileOtpVerification = async () => {
    // verify mobile Otp
    let mobile;
    if (storeUserName !== undefined) {
      mobile = storeUserName;
    }
    let body = {
      otpVerifyType: "mobile",
      otp: mobileOTP,
      business_email_or_mobile: mobile.business_mobile,
      username: mobile.username,
    };
    const responseData: any = await ApiService.post("/auth/verify", body);
    if (responseData.status === 200) {
      setresendMobileBtn(true);
      var getmobileOTP = responseData.data;
      if (getmobileOTP.Active === true && responseData.status === 200) {
        // verify mobile otp disabled state
        setdisabledmobileVerifybtn(true);
        // mobile field state disabled
        setMobileFieldDisabled(true);
        // set mobile status
        setMobileVerifyStatus(true);
        // success WWW
        SuccessMsg("Mobile Verified Successfully");
      }
    } else {
      console.log(responseData);
      setdisabledmobileVerifybtn(true);
      seterrorMobileVerifystatus(true);
    }
  };

  const resendOTPTimeSet = async () => {
    setresendMobileBtn(true);
    setSeconds(30);
    let mobile;
    if (storeUserName !== undefined) {
      mobile = storeUserName;
    }
    let body = {
      verificationType: "mobile",
      username: mobile.username,
    };
    const responseData: any = await ApiService.post("/auth/resendotp", body);
    if (responseData.status === 200) {
      SuccessMsg("Otp Successfully Send to your Registor Mobile Number");
    } else {
      ErrorMsg("Something went wrong, Please try again");
      console.log("error", responseData);
    }
  };
  const resendOTPOnClick = () => {
    setTimeout(() => {
      if (seconds === 0 && !MobileFieldDisabled) {
        setresendMobileBtn(false);
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
  };
  useEffect(() => {
    resendOTPOnClick();
  }, [seconds]);

  const resendOTPTimeSetEmail = async () => {
    setresendEmailBtn(true);
    let mobile;
    if (storeUserName !== undefined) {
      mobile = storeUserName;
    }
    setSecondsEmail(30);
    let body = {
      verificationType: "email",
      username: mobile.username,
    };
    const responseData: any = await ApiService.post("/auth/resendotp", body);
    if (responseData.status === 200) {
      SuccessMsg("Otp Successfully Send to your Registor Mobile Numbe");
    } else {
      ErrorMsg("Something went wrong, Please try again");
      console.log("error", responseData);
    }
  };
  const resendOTPOnClickEmail = () => {
    setTimeout(() => {
      if (seconds === 0) {
        setresendEmailBtn(false);
      } else {
        setSecondsEmail(seconds - 1);
      }
    }, 1000);
  };
  useEffect(() => {
    resendOTPOnClickEmail();
  }, [seconds]);
  const emailOtpVerification = async () => {
    setOpen(true);
    // verify mobile Otp
    let email;
    if (storeUserName !== undefined) {
      email = storeUserName;
    }
    let body = {
      otpVerifyType: "email",
      otp: emailOTP,
      business_email_or_mobile: email.business_email,
      username: email.username,
    };
    const responseData: any = await ApiService.post("/auth/verify", body);
    if (responseData.status === 200) {
      var getEmailOTP = responseData.data;
      localStorage.setItem("userRole",responseData.data.userRole)
      // setEmailVerifyStatus(true);
      SuccessMsg("Email Verified Successfully");
      if (MobileVerifyStatus) {
        dispatch(storeToken(getEmailOTP.result));
        dispatch(userInfo(getEmailOTP.result.userKyc));

        dispatch(loginUser(true));
        setOpen(false);
        navigate("/auth/onboardingpays");
      } else {
        SuccessMsg("Verification Pending, Please Verify Mobile !");
      }
    } else {
      setOpen(false);
      ErrorMsg("Invalid OTP, Please Try Again");
    }
  };

  useEffect(() => {
    const items = localStorage.getItem("User");
    console.log(items ? items[1] : items);
  });
  return (
    <Box style={{ marginBottom: "12px" }}>
      <TextField
        disabled={MobileFieldDisabled}
        size="small"
        error={errorMobileVerifystatus}
        helperText={
          errorMobileVerifystatus ? "Invalid OTP, Please try again !" : ""
        }
        sx={{
          "& .MuiFormLabel-root": {
            color: "#919EAB",
            fontFamily: "Public Sans",
            fontSize: 15,
            mt: 0.3,
          },
        }}
        type="number"
        name="email"
        autoFocus
        label="Mobile Verification Code"
        className={"hide-spin-buttons"}
        id="email"
        onChange={handleOnChangeMobileOtp}
        fullWidth
        InputProps={{
          sx: {
            height: "38px",
            borderRadius: "8px",
            // minWidth: "340px",
            borderColor: "#919EAB",
            marginBottom: 2,
          },
        }}
      />
      <Box
        display={"flex"}
        justifyContent={"center"}
        sx={{ "& button": { my: 2 } }}
      >
        <Stack display={"flex"} direction={"row"} spacing={4} sx={{ my: 2 }}>
          <Button
            disabled={resendMobileBtn}
            style={{
              color: !resendMobileBtn ? "#007B55" : "",
              fontFamily: "Public Sans",
              fontWeight: "700",
              background:
                "var(--transparent-primary-16, rgba(0, 171, 85, 0.16))",
              borderRadius: "8px",
              minWidth: "162px",
              fontSize: "14px",
              gap: "8px",
              padding: "6px",
              textTransform: "none",
            }}
            size="small"
            onClick={resendOTPTimeSet}
          >
            {!resendMobileBtn ? (
              <img aria-disabled src={shapeResend} alt="" />
            ) : (
              ""
            )}
            Resend Code
          </Button>

          <Button
            disabled={disabledmobileVerifybtn}
            onClick={mobileOtpVerification}
            style={{
              fontWeight: 700,
              color: disabledmobileVerifybtn
                ? "var(--action-light-disabled, rgba(145, 158, 171, 0.80))"
                : "white",
              background: disabledmobileVerifybtn
                ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                : "#303981",
              borderRadius: "8px",
              display: "flex",
              width: "162px",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
              textTransform: "none",
            }}
            size="small"
          >
            Verify
          </Button>
        </Stack>
      </Box>
      {seconds > 0 && !MobileVerifyStatus ? (
        <Typography
          fontFamily={"Public Sans"}
          fontSize={"14px"}
          fontWeight={500}
          color={"#919EAB"}
        >
          You can try again after <b>{seconds}</b> seconds
        </Typography>
      ) : (
        ""
      )}

      {MobileVerifyStatus ? (
        <>
          <TextField
            size="small"
            sx={{
              "& .MuiFormLabel-root": {
                color: "#919EAB",
                fontFamily: "Public Sans",
                fontSize: 15,
              },
            }}
            type="number"
            className={"hide-spin-buttons"}
            name="email"
            onChange={handleOnChangeEmailOtp}
            label="Email Verification Code"
            id="email"
            fullWidth
            autoFocus
            InputProps={{
              style: {
                height: "38px",
                borderRadius: "8px",
                borderColor: "#919EAB",
                // margin: "20px 0 0 0",
                marginBottom: 4,
              },
            }}
          />
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ "& button": { m: "10px", my: 2 } }}
          >
            <Stack
              display={"flex"}
              direction={"row"}
              spacing={4}
              sx={{ my: 2 }}
            >
              <Button
                onClick={resendOTPTimeSetEmail}
                disabled={resendEmailBtn}
                style={{
                  color: !resendEmailBtn ? "#007B55" : "",
                  fontFamily: "Public Sans",
                  fontWeight: "700",
                  background:
                    "var(--transparent-primary-16, rgba(0, 171, 85, 0.16))",
                  borderRadius: "8px",
                  minWidth: "162px",
                  fontSize: "14px",
                  gap: "8px",
                  padding: "6px",
                  textTransform: "none",
                }}
                size="small"
              >
                {!resendEmailBtn ? <img src={shapeResend} alt="" /> : ""}
                Resend Code
              </Button>
              <Button
                disabled={disabledEmailVerifybtn}
                style={{
                  fontWeight: 700,
                  color: disabledEmailVerifybtn
                    ? "var(--action-light-disabled, rgba(145, 158, 171, 0.80))"
                    : "white",
                  background: disabledEmailVerifybtn
                    ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                    : "#303981",
                  borderRadius: "8px",
                  display: "flex",
                  width: "160px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "4px",
                  textTransform: "none",
                }}
                onClick={emailOtpVerification}
                size="small"
              >
                Verify
              </Button>
            </Stack>
          </Box>
          <SimpleLoader props={open} />
          {secondsEmail > 0 ? (
            <Typography
              fontFamily={"Public Sans"}
              fontSize={"14px"}
              fontWeight={500}
              color={"#919EAB"}
            >
              You can try again after <b>{secondsEmail}</b> seconds
            </Typography>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </Box>
  );
};

export default OtpVerifyForm;
